body {
  /* background-color: unset; */
  background: #f0f0f1;
  color: #1D1D1D;
}

html {
  background: #f0f0f1;
}

a {
  color: #0866FF
}

.a-disabled {
  color: #667085
}

.a-disabled:hover {
  color: #667085
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1D1D1D;
}
/* table {
  border: 1px solid #F0F1F3;
  border-radius: 20px;
} */
table thead tr {
  background: #EBF5FF;
  color: #1D1D1D;
  font-size: 30px;
  border: none;
}

.table thead th {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border: none;
}

.table td, .table th {
  font-size: 0.7rem;
  white-space: nowrap;
}

.btn {
  padding: 0.4rem 1.25rem;
  font-size: 0.700rem;
  padding: 0.4rem 0.8rem;
}

.btn.disabled, .btn:disabled {
  background-color: grey;
  border: 1px solid transparent;
  box-shadow: none;
}

.btn-round {
  padding: 0px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
}

.btn-delete {
  color: #fff;
  background-color: red;
  border-color: red;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-delete:hover {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4; }
  .btn-delete:focus, .btn-delete.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5); }
  .btn-delete.disabled, .btn-delete:disabled {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4; }
  .btn-delete:not(:disabled):not(.disabled):active, .btn-delete:not(:disabled):not(.disabled).active,
  .show > .btn-delete.dropdown-toggle {
    color: #fff;
    background-color: #324cdd;
    border-color: #5e72e4; }
    .btn-delete:not(:disabled):not(.disabled):active:focus, .btn-delete:not(:disabled):not(.disabled).active:focus,
    .show > .btn-delete.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5); }

.mac-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mac-scrollbar::-webkit-scrollbar-track {
  background-color: white;
}

/* scrollbar itself */
.mac-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid white;
}

/* set button(top and bottom of the scrollbar) */
.mac-scrollbar::-webkit-scrollbar-button {
  display:none;
}

.mac-scrollbar-white::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mac-scrollbar-white::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.mac-scrollbar-white::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.mac-scrollbar-white::-webkit-scrollbar-button {
  display:none;
}

@media (min-width: 768px){
  .product-modal {
    max-width: 90%;
  }
}

@media (min-width: 768px){
  .status-modal {
    max-width: 650px;
  }
}

@media (min-width: 992px){
  .status-modal {
    max-width: 800px;
  }
}

/* @media (min-width: 992px){
  .product-modal {
    max-width: 800px;
  }
} */

.cursor-pointer {
  cursor: pointer;
}

.navbar-nav>li>a>i {  
  padding-left: 4px;
}

.navbar-vertical .navbar-nav .nav-link {
  color: #757575;
}
.navbar-light .navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav .nav-link:focus,
.navbar-vertical .navbar-nav .nav-link.active {
  border-radius: 8px;
  background-color: #F2F8FD;
  color: #0866FF;
}
.navbar-vertical .navbar-nav .nav-link.active:before {
  display: none;
}

.btn-primary {
  background-color: #0866FF;
  border-color: #0866FF;
}

.btn-secondary {
  background-color: #EBF5FF;
  border-color: #EBF5FF;
  box-shadow: none;
  border: 1px solid #B6D3F0;
}

.btn-tertiary {
  background-color: #FFCA3A;
  border-color: #FFCA3A;
  color: #282525;
}

.btn-grey {
  background-color: #EDEDED;
  border-color: #EDEDED;
}

.btn-square {
  padding: 0.4rem 0.5rem;
}

.btn-default {
  background-color: white;
  border-color: white;
  color: #272D37;
}
.btn-default:hover, .btn-default:focus, .btn-default:active {
  background-color: transparent !important;
  color: #0866FF !important;
}
.bg-primary {
  background-color: #0866FF !important;
}

/* .bg-primary, .fill-default {
  fill: #F6F6F6 !important;
  background: #F6F6F6 !important;
  color: black !important;
} */
.bg-product {
  background-color: #F2F8FD;
}
.bg-product-highlight {
  background-color: #FDFBE9;
}

.bg-grey {
  background-color: #E4E4E4;
}

.bg-white {
  background-color: #ffffff;
}

.bg-yellow {
  background-color: #FFCA3A;
}

.bg-light-grey {
  background-color: #F2F2F2;
}

.bg-secondary {
  fill: #EBF5FF !important;
  background: #EBF5FF !important;
  /* color: black !important; */
}
.fill-default {
  fill: #F6F6F6 !important;
}

.pagination {
  display: flex;
  gap: 5px;
}

.page-link {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.page-item .page-link {
  background: #EBF5FF !important;
  border-radius: 6px !important;
  color: #0866FF !important;
}

.page-item.active .page-link {
  background: #0866FF !important;
  color: #F6F6F6 !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #F6F6F6 !important;
  font-weight: 600 !important;
}
.bg-custom-yellow {
  background: #FDF5DD !important;
  color: black !important;
}

table tbody tr th, table tbody tr td, table tbody tr td span {
  color: #272D37;
}
input {
  height: 35px !important;
}
.height-30-important {
  height: 30px !important;
}
.product-max-width {
  max-width: 800px;
  margin: auto;
}

/* .table thead th {
  font-size: 13px !important;
}
table tbody td {
  font-size: 12px !important;
} */
table tbody td .dropdown a {
  padding: 0;
}

.nav-item {
  cursor: pointer;
}

.bg-color {
  background-color: #f0f0f1 !important;
}

.input-card {
  border-radius: 8px;
  border: 1px solid #E0E2E7;
  padding: 0.5rem;
  gap: 10px;
  height: 55px;
}

.component-chip {
  max-width: 180px;
}

.component-chip:hover {
  background-color: #0866FF;
  color: #F6F6F6;
}

.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}

.h3-link {
  cursor: 'pointer'
}

.h3-link:hover {
  cursor: 'pointer';
  color: #0866FF;
}

.missing-component-card {
  border: dashed 1px red;
  background: pink;
  color: red;
}

.xs {
  font-size: 0.6rem;
}

.color-primary {
  color: #0866FF;
}

.color-text {
  color: #525f7f
}

.dropdown-menu {
  padding: 0.5rem;
}

.dropdown-item {
  padding: 0.5rem;
  border-radius: 3px;
}

.dropdown-menu .dropdown-item {
  font-size: 0.6rem;
}

.dropdown-item:hover {
  background-color: #EBF5FF;
}

.input-group-alternative {
  box-shadow: none;
  border: 1px solid #E0E2E7;
}

.card-body {
  padding: 1rem;
}

.preview-container {
  border: 1px solid #0866FF;
  background-color: #EBF5FF;
}

.additional-info-container {
  border: 1px solid #0866FF;
}

.modal-back {
  float: left;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: 0.5;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal-close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: 0.5;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.component-selector-item {
  cursor: pointer;
}
.component-selector-item:hover {
  background-color: #FFFFFF;
}

.component-code {
  font-style: italic;
  font-size: 0.6rem;
  color: #757575;
  white-space: nowrap;
}

.border-primary {
  border: 1px solid #9DD0FF !important;
}

.form-control {
  color: #0E0E0E;
}
.form-control:focus {
  color: #0E0E0E;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.modal-dialog-top {
  top: 100px;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.border-dashed {
  border-style: dashed;
}

.table-flush > thead {
  height: 50px;
}

.color-black {
  color: #1D1D1D;
}

.page-range-input {
  font-style: italic !important;
  font-size: 80%;
  font-weight: 400;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-width: 1px;
  width: 24px;
  padding-left: 4px;
  padding-right: 4px;
}

 @media (min-width: 768px) {
  .packaging-component-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 8px;
  }
 }

 @media (min-width: 992px) {
  .packaging-component-wrapper {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
 }

.packaging-img-wrapper {
  min-height: 200px;
}

@media (min-width: 576px) {
  .packaging-img-wrapper {
    min-height: 100px;
  }
 }

 @media (min-width: 768px) {
  .packaging-img-wrapper {
    min-height: 70px;
  }
 }

 @media (min-width: 992px) {
  .packaging-img-wrapper {
    min-height: 100px;
  }
 }

 @media (min-width: 1200px) {
  .packaging-img-wrapper {
    min-height: 130px;
  }
 }
 @media (min-width: 1300px) {
  .packaging-img-wrapper {
    min-height: 160px;
  }
 }

 .product-image-review {
  height: 70px;
  width: 70px;
 }

 .product-image-upload-button {
  cursor: pointer;
  height: 70px;
  width: 70px;
 }